import { defineStore } from 'pinia'

export const useUIStore = defineStore('ui', () => {
  const scrollLock = reactive({
    enabled: false,
    scrollTop: 0,
  })

  const showBanner = ref(false)
  const showNavMenu = ref(false)

  const toggleNavMenu = () => {
    showNavMenu.value = !showNavMenu.value
  }

  return {
    scrollLock,
    showBanner,
    showNavMenu,
    toggleNavMenu,
  }
})
